function init(callback3DS, that, apiKey, endpoint) {
    $(function () {

        // 3D SECURE TO EXECUTE A TRANSACTION
        // INITIATE LIBRARY
        var tds = new ThreeDS(
            "transaction-form",
            apiKey,
            null,
            {
                endpoint: endpoint,
                verbose: false,
                showChallenge: true,
                iframeId: '3DsecureChallengeIFrame',
                autoSubmit: false,
                // PROMPT - IS CALLED WHEN THERE IS A CHALLENGE
                prompt: function (data) {
                    $('#Modal3DS').removeClass('hidden');
                    $('#Modal3DS').addClass('flex');
                },

            },
        );



        // VERIFY
        $('#btnGoTransaction').click(function ($event) {

            let attemptCounter = 0;
            tds.verify(

                // THIS FUNCTION IS CALLED AFTER 3DS IS COMPLETELLY EXECUTED .
                // THIS IS CALLED EVEN IF THE USER IS CHALLENGED, AND HAS ADDED WRONG INFORMATION
                function (response) {

                    $('#Modal3DS').addClass('hidden');
                    $('#Modal3DS').removeClass('flex');
                    if (response
                        && (response.protocolVersion === "2.1.0" || response.protocolVersion === "2.2.0")
                        && response.eci
                        && response.dsTransId
                        && response.authenticationValue) {

                        callback3DS(that, "successV2", response);

                        return;
                    }

                    if (response
                        && response.protocolVersion === "1.0.2"
                        && response.eci
                        && response.xid
                        && response.cavv) {

                        callback3DS(that, "successV1", response);

                        return;
                    }
                    if(attemptCounter > 0){
                        return;
                    }
                    callback3DS(that, response);
                    attemptCounter++;
                },
                // THIS FUNCTION IS CALLED WHEN THERE IS A GENERIC ERROR WITH THE LIBRARY
                // OR WHEN THE CARD INFORMED IS NOT VALID, NOT A VISA CARD
                function (response) {
                    if(attemptCounter > 0){
                        return;
                    }
                    $('#Modal3DS').addClass('hidden');
                    $('#Modal3DS').removeClass('flex');
                    callback3DS(that, response);
                    $event.preventDefault();
                    attemptCounter++;
                },
                // PARAMETERS
                {
                    threeDSRequestorURL: 'https://zumrails.com',
                    amount: Number(document.getElementById("x_amount").value)
                });
        });
    });

}
